<template>
  <div id="Vsitem" :class="ItemData != null && WinDefeat=='胜利' ? 'vsitems' : ''" >
    <div class="core">
      <div class="exhibition" v-show="active == '1'">
        <div class="already" v-show="ItemData != null">
          <div class="user">
            <img
            
              v-if="ItemData != null"
              :src="ItemData.avatar | ImgFilter"
              alt=""
            />
            <p v-if="ItemData != null">{{ ItemData.name }}</p>
          </div>
        </div>
        <div class="not" v-show="ItemData == null">
          <!-- <van-icon name="replay"  color="#1989fa" size="30"/> -->
          <img
          style="width:45px"
            src="../../../../assets/images/q2/pk/addbtn.png"
            @click="PostArenaJoin"
            alt=""
          />
          <p style="color:white">等待玩家</p>
        </div>
      </div>

      <Slidemod
        v-if="active == '2'"
        :game_arena_box="game_arena_box"
        :ItemData="ItemData"
        :second="second"
        :can="can"
        @setAnimation="setAnimation"
      ></Slidemod>

      <div class="fruit info" v-show="active == '3'" > 
        <p>{{ WinDefeat }}</p>
        <div class="user">
          <img
            v-if="ItemData != null"
            :src="ItemData.avatar | ImgFilter"
            alt=""
          />
          <span v-if="ItemData != null">{{ ItemData.name }}</span>
        </div>
        <p class="money">
          <img
            v-show="ItemData != null"
            src="@/assets/images/public/Gold.png"
            alt=""
          />
          <countTo
            :startVal="startVal"
            :endVal="endVal"
            :duration="1000"
            :decimals="2"
          ></countTo>
        </p>
        <div class="shadow" :class="WinDefeatSty"></div>
      </div>
    </div>
    <div class="info"  v-show="active == '3'">
      
        <img class="win" v-if="WinDefeat=='胜利'" src="../../../../assets/images/q2/pkin/win.png" alt="">
        <p v-else> {{WinDefeat}} </p>
      <!-- <div class="user">
        <img
          v-if="ItemData != null"
          :src="ItemData.avatar | ImgFilter"
          alt=""
        />
        <span v-if="ItemData != null">{{ ItemData.name }}</span>
      </div> -->
     
    </div>
    <div>
      <p class="money">
        <img
          v-show="ItemData != null"
          src="@/assets/images/public/Gold.png"
          alt=""
        />
        <countTo
          :startVal="startVal"
          :endVal="endVal"
          :duration="1000"
          :decimals="2"
        ></countTo>
      </p>
    </div>
    <div class="Column" v-show="haveArr.length > 0">
      <div
        class="column_item"
        v-for="(item, index) in haveArr"
        :key="index"
        :style="`background-image:url(${require('@/assets/images/q2/openbox/'+ item.lv +'.png')})`"
      >
      <!-- :style="{ 'background-image': 'url(' + item.lv_bg_image + ')' }" -->

        <div class="column_pic">
          <img :src="item.cover" alt="" />
        </div>
        <p>{{ item.name }}</p>
        <span
          ><img src="@/assets/images/public/Gold.png" alt="" />{{
            item.bean
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to"; //数字过度组件
import Slidemod from "./Slidemod/index.vue"; //动画组件
import { ArenaJoin } from "@/network/api.js";
export default {
  name: "Vsitem",
  props: {
    ItemData: {
      //玩家信息
      type: Object,
    },
    PlayerArr: {
      //全部玩家信息(处理结果使用)
      type: Array,
    },
    game_arena_box: {
      //宝箱信息
      type: Array,
    },
    game_arena_id: {
      //对战id
      type: Number,
    },
    seat: {
      //座位号
      type: Number,
    },
    status: {
      //进程
      type: Number,
    },
    win_user: {
      //胜利玩家列表(有平局)
      type: Array,
    },
  },
  data() {
    return {
      active: "1", //进程相关(模块显示隐藏)
      can: false, //动画开始开关
      second: 0, //动画次数(物品列表下标及中奖列表下标)
      process: new Audio(require("@/assets/audio/process.mp3")), //开奖过程声音
      haveArr: [], //中奖物品(下栏目展示)
      startVal: 0, //奖品价值金币(始)
      endVal: 0, //奖品价值金币(终)
      WinDefeat: "", //胜利失败文字
      WinDefeatSty: "", //胜利失败样式
    };
  },

  methods: {
    //加入对战
    PostArenaJoin() {
      ArenaJoin(this.game_arena_id, this.seat).then((res) => {});
    },

    //开始动画
    Start() {
      this.active = "2";
      setTimeout(() => {
        //因v-if,立即改变子组件监听不到
        this.can = true;
        this.process.play(); //播放声音
      }, 100);
    },

    //接收子组件(单次动画结束)
    setAnimation(v) {
      this.can = false;
      if (v == true) {
        this.second += 1; //物品列表下标及中奖列表下标及回合数+1
        this.haveArr.unshift(
          this.ItemData.game_award[this.second - 1].box_award
        );
        this.endVal += Number(
          this.ItemData.game_award[this.second - 1].box_award.bean
        );
        setTimeout(() => {
          this.$emit("setSecond", this.second); //动画开始发送回合数
          this.can = true; //改变值触发动画
          this.process.play(); //播放声音
        }, 2000);
      } else {
        //宝箱已全部开完
        this.haveArr.unshift(this.ItemData.game_award[this.second].box_award); //push最后奖品
        this.endVal += Number(
          this.ItemData.game_award[this.second].box_award.bean
        ); //增加最后奖品价值
        setTimeout(() => {
          this.beam(); //处理结果
        }, 3000);
      }
    },

    //结果处理
    beam() {
      if (this.win_user.length == 1) {
        //单人获胜
        this.single();
      } else {
        //多人获胜
        this.many();
      }
    },
    //单人胜利
    single() {
      let A_Arr = this.win_user.find((v) => {
        //确定是否获胜
        return v == this.ItemData.user_id;
      });
      //胜利操作
      if (A_Arr !== undefined) {
        this.WinDefeat = "胜利";
        this.WinDefeatSty = "Win";
        let B_Arr = [];
        for (let i = 0; i < this.PlayerArr.length; i++) {
          //获取全部玩家信息
          B_Arr = B_Arr.concat(this.PlayerArr[i].game_award);
        }
        let C_Arr = [];
        let EndNum = 0;
        for (let k = 0; k < B_Arr.length; k++) {
          //获取全部玩家获得的奖品
          C_Arr.push(B_Arr[k].box_award); //全部归胜者
          EndNum += +Number(B_Arr[k].box_award.bean); //金币全部累加归胜者
        }
        this.haveArr = C_Arr; //下奖品栏赋值
        this.endVal = EndNum; //价值金币赋值
      } else {
        //失败操作
        this.WinDefeat = "失败";
        this.WinDefeatSty = "Defeat";
        this.haveArr = [
          //凉凉奖励
          {
            cover: require("@/assets/images/Vs/FailImg.png"),
            name: "凉凉奖励",
            bean: "0.01",
            lv:1
          },
        ];
        this.endVal = 0.01; //价值金币赋值
      }

      this.active = "3"; //显示胜负模块
    },
    //多人胜利
    many() {
      let A_Arr = this.win_user.find((v) => {
        //确定是否获胜
        return v == this.ItemData.user_id;
      });
      //胜利操作
      if (A_Arr !== undefined) {
        this.WinDefeat = "胜利";
        this.WinDefeatSty = "Win";
        let Alone = this.PlayerArr.filter((v) => {
          //筛选应属数据
          return v.id == this.ItemData.id;
        });

        this.haveArr = Alone[0].game_award; //下奖品栏赋值(应属数据)

        let E_Arr = [];
        for (let i = 0; i < this.PlayerArr.length; i++) {
          //全部玩家信息
          E_Arr = E_Arr.concat(this.PlayerArr[i].game_award);
        }
        let TotalNum = 0;
        for (let k = 0; k < E_Arr.length; k++) {
          //全部奖品
          TotalNum += Number(E_Arr[k].box_award.bean); //全部奖品价值
        }
        //奖品价值为全部奖品价值/获胜人数
        this.endVal = TotalNum / this.win_user.length;
      } else {
        //失败操作
        this.WinDefeat = "失败";
        this.WinDefeatSty = "Defeat";
        this.haveArr = [
          {
            cover: require("@/assets/images/Vs/FailImg.png"),
            name: "凉凉奖励",
            bean: "0.01",
            lv:1
          },
        ];
        this.endVal = 0.01;
      }

      this.active = "3"; //显示胜负模块
    },
  },

  created() {
    this.$emit("setSecond", this.second); //初始发送事件(回合数)
  },

  watch: {
    status(v) {
      if (v == 1) {
        //为1播放动画
        this.Start();
      } else if (v == 2) {
        //为2直接显示结果
        this.beam();
      }
    },
  },
  components: {
    Slidemod, //动画组件
    countTo, //数字过度
  },
};
</script>

<style lang="scss">
.vsitems{
  background: url('../../../../assets/images/q2/pkin/someone.png') no-repeat center !important;
  background-size: 100% 100% !important;
  height: 700px;
}
#Vsitem {
  flex: 1;
  border-right: 1px solid #181e2b;
  background: url('../../../../assets/images/q2/pkin/nobody.png') no-repeat center ;

  background-size: 100% 100%;
  height: 700px;
  &:last-child {
    border-right: none;
  }
  &:nth-child(2n) {
    .info {
      // border-bottom: 1px solid #aa00ff;
      background-image: linear-gradient(
        180deg,
        rgba(170, 0, 255, 0) 50%,
        rgba(170, 0, 255, 0.15)
      );
    }
  }
  .core {
    width: 100%;
    height: 140px;
    background: rgba($color: #000000, $alpha: 0.2);
    border-radius: 2px;
    .exhibition {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .user {
        width: 100%;
        color: #fff;
        font-size: 10px;
        text-align: center;
        margin-top: 2px;
        // height: 20px;
        line-height: 20px;
        overflow: hidden;
        img {
          width: 35px;
          height: 35px;
          // border-radius: 50%;
          overflow: hidden;
          border: 2px solid #34F3FB;
          margin-right: 2px;
          vertical-align: middle;
        }
      }
      .not {
        text-align: center;
        .van-button {
          display: block;
          margin-top: 5px;
        }
      }
    }
    .fruit {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      flex-wrap: wrap;
      align-content: center;
      height: 145px !important;
      // background: url('../../../../assets/images/q2/pkin/winbac.png') no-repeat center; 
      background-size: 92% 92%;
      p {
        position: relative;
        z-index: 1;
        font-size: 14px;
        font-weight: bold;
      }
      .shadow {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        &.Win {
          // box-shadow: rgb(8, 186, 76) 0px 0px 40px 18px;
        }
        &.Defeat {
          // box-shadow: rgb(170, 0, 255) 0px 0px 5em 2.4em;
        }
      }
    }
  }
  .info {
    width: 100%;
    // border-bottom: 1px solid #2ac88a;
    box-sizing: border-box;
    height: 70px;
    background-image: linear-gradient(
      180deg,
      rgba(60, 162, 120, 0) 50%,
      rgba(60, 162, 120, 0.15)
    );
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color:white;
    .win{
      width: 90%;
      margin: 0 auto;
      text-align: center;
    }
    .user {
      width: 100%;
      color: #fff;
      font-size: 10px;
      text-align: center;
      margin-top: 2px;
      height: 20px;
      line-height: 20px;
      overflow: hidden;
      img {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #ffba2e;
        margin-right: 2px;
        vertical-align: middle;
      }
    }
    .money {
      width: 100%;
      margin-top: 2px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f1bc60;
      font-size: 12px;
      img {
        width: 16px;
        vertical-align: middle;
      }
    }
  }
  .Column {
    height: 450px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: scroll;
    .column_item {
      width: 98%;
      margin-top: 2px;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 4px;
      text-align: center;
      padding-bottom: 20px;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .column_pic {
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
        // padding-bottom: 2%;
        padding-top: 3%;
        
        img {
          max-width: 70%;
          height: 70px;
        }
      }
      p {
        font-size: 10px;
        color: #fff;
        margin-bottom: 2px;
        width: 80%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        /* 将对象作为弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 限制在一个块元素显示的文本的行数 */
        /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
        -webkit-line-clamp: 1;
        /* 设置或检索伸缩盒对象的子元素的排列方式 */
        -webkit-box-orient: vertical;

      }
      span {
        width: 100%;
        font-size: 10px;
        color: #ebce59;
        img {
          width: 14px;
          vertical-align: middle;
        }
      }
    }
  }
}
.money {
    width: 100%;
    margin-top: 2px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f1bc60;
    font-size: 12px;
    img {
      width: 16px;
      vertical-align: middle;
    }
  }
</style>
